<template>
    <div class="QuestionPage">
        <div class="QuestionPageInner">
            <div class="question">{{question.question}}</div>
            <vueCodeEditor ref="codeEditor" class="code" @change="onCodeChange"/>
            <div class="tests">
                <TestResult v-for="(testResult, i) of testResults" :result="testResult" :key="i"/>
            </div>
        </div>
        <div class="toolbar" :class="{next: answerCorrect}" @click="onClickNext">
            <div v-if="answerCorrect === false">TEST</div>
            <div v-if="answerCorrect === true">NEXT</div>
        </div>
    </div>
</template>

<script>
const {ref, onMounted, nextTick, watch} = require("vue")
const deepEquals                 = require("phpack--util--deep-equal")

import {loadItemLS, addItemLS} from "phpack--client--util--local-storage"
import vueCodeEditor from "./vueCodeEditor.vue"
import TestResult from "./TestResult.vue"

export default {
    components: {vueCodeEditor, TestResult},
    props: {
        question: Object
    },
    emits: ["next-question"],
    setup(props, context) {
        const codeEditor    = ref()
        const testResults   = ref()
        const answerCorrect = ref(false)

        const code = ref(props.question.start)

        const onClickNext = function() {
            if (answerCorrect.value === true) {
                addItemLS('question-answer', null)
                return context.emit('next-question')
            }

            testResults.value = runTests(props.question, code.value)
            answerCorrect.value = testResults.value.every(testResult => testResult.result === true)
        }

        const onCodeChange = function(content) {
            code.value = content
            addItemLS('question-answer', content)
        }

        const setCode = function(newCode) {
            codeEditor.value.setContent(newCode)
            answerCorrect.value = false
            testResults.value   = undefined
        }

        // watch(() => props.question, (newValue, oldValue) => {
        //     codeEditor.value.setContent(props.question.start)
        //     answerCorrect.value = false
        //     testResults.value   = undefined
        // })

        return {codeEditor, testResults, answerCorrect, onClickNext, onCodeChange, setCode}
    }
}

function runTests(question, code) {
    const testResults = []

    for (let test of question.tests) {
        testResults.push(runTest(test, code))
    }

    return testResults
}

function runTest(test, code) {
    function expect(result) {
        let resultInner = result

        return {
            toEqual: function(expectedResult) {
                return deepEquals(resultInner, expectedResult)
            }
        }
    }

    const script = code + "\n\n" + test.code

    let result, error

    try {
        result = eval(script)
    } catch(e) {
        error = e
    }

    return {code: test.code, result, error}
}
</script>

<style lang="scss" scoped>
.QuestionPage {
    display: flex;
    flex-direction: column;
    height: 100%;

    .QuestionPageInner {
        flex: 1;

        overflow: auto;

        .question {
            background-color: white;
            min-height: 100px;

            display: flex;
            align-items: center;
            justify-content: center;

            margin: 5px;
        }

        .code {
            background-color: #272823;
            min-height: 18px;
        }

        .tests {
            background-color: white;
            min-height: 100px;
        }
    }

    .toolbar {
        height: 50px;
        background-color: #303f9f;

        cursor: pointer;

        display: flex;
        align-items: center;
        justify-content: center;

        &.next {
            background-color: #388e3c;
        }
    }
}
</style>