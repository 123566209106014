module.exports.question = "Create a function called sum that returns the sum of the first and second parameter"
module.exports.start = 
`function sum(a, b) {

}`

module.exports.tests =  [
    {
        code: `expect(sum(1, 2)).toEqual(3)`
    },
    {
        code: `expect(sum(5, 10)).toEqual(15)`
    },
    {
        code: `expect(sum(-5, 5)).toEqual(0)`
    },
]