<template>
    <QuestionPage v-show="allQuestionsCompleted === false" ref="QuestionPage" :question="currentQuestion" @next-question="onNextQuestion"/>
    <div v-if="allQuestionsCompleted === true" class="questions-completed">
        <svgl class="smile-wink" name="smile-wink"/>
        <div>Well done, all questions completed!</div>
        <div class="start-again-button" @click="startAgain">Start Again</div>
    </div>
</template>

<script>
import svgl from "phpack--client--component--svg"
import QuestionPage from './components/QuestionPage.vue'
import {loadItemLS, addItemLS} from "phpack--client--util--local-storage"
const questions  = require("./questions/index.js")

export default {
    components: {QuestionPage, svgl},
    data() {
        return {
            questions,
            questionIndex: 0,
            allQuestionsCompleted: false
        }
    },
    computed: {
        currentQuestion() {
            return this.questions[this.questionIndex]
        }
    },
    methods: {
        onNextQuestion() {
            if (this.questionIndex + 1 >= questions.length) {
                this.allQuestionsCompleted = true
                addItemLS("allQuestionsCompleted", true)
            } else {
                this.questionIndex++
                addItemLS("questionIndex", this.questionIndex)
                this.$refs.QuestionPage.setCode(this.currentQuestion.start)
            }
        },
        startAgain() {
            this.questionIndex = 0
            addItemLS("questionIndex", 0)
            this.allQuestionsCompleted = false
            addItemLS("allQuestionsCompleted", false)
            this.$refs.QuestionPage.setCode(this.currentQuestion.start)
        }
    },
    mounted() {
        const allQuestionsCompleted = loadItemLS("allQuestionsCompleted")
        if (allQuestionsCompleted !== null) this.allQuestionsCompleted = allQuestionsCompleted
        const questionIndex         = loadItemLS("questionIndex")
        if (questionIndex !== null) this.questionIndex = questionIndex

        const savedAnswer = loadItemLS('question-answer')
        if (savedAnswer !== null) {
            console.log(savedAnswer)
            this.$refs.QuestionPage.setCode(savedAnswer)
        }
    }
}
</script>

<style>
body, html {
    margin: 0px;
    height: 100%;
    overflow: hidden;
}

* {
    box-sizing: border-box;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    max-height: 100%;

    display: flex;
    flex-direction: column;

    overflow: hidden;
}

.smile-wink {
    width: 200px;
    height: 200px;
    margin: 10px;
}

.questions-completed {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.start-again-button {
    background-color: #388e3c;
    margin-top: 20px;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
}
</style>
