module.exports.question = `Create a function called parseRomanNumerial returns the value of a roman numeral as an integer according to the standard notation.
See https://en.wikipedia.org/wiki/Roman_numerals`
module.exports.start = 
`function parseRomanNumerial(value) {

}`

module.exports.tests =  [
    {code: `expect(parseRomanNumerial('HEY')).toEqual(false)`},
    {code: `expect(parseRomanNumerial('I')).toEqual(1)`},
    {code: `expect(parseRomanNumerial('II')).toEqual(2)`},
    {code: `expect(parseRomanNumerial('III')).toEqual(3)`},
    {code: `expect(parseRomanNumerial('IV')).toEqual(4)`},
    {code: `expect(parseRomanNumerial('V')).toEqual(5)`},
    {code: `expect(parseRomanNumerial('VI')).toEqual(6)`},
    {code: `expect(parseRomanNumerial('VII')).toEqual(7)`},
    {code: `expect(parseRomanNumerial('VIII')).toEqual(8)`},
    {code: `expect(parseRomanNumerial('IX')).toEqual(9)`},
    {code: `expect(parseRomanNumerial('X')).toEqual(10)`},
    {code: `expect(parseRomanNumerial('XI')).toEqual(11)`},
    {code: `expect(parseRomanNumerial('XII')).toEqual(12)`},
    {code: `expect(parseRomanNumerial('XIII')).toEqual(13)`},
    {code: `expect(parseRomanNumerial('XIV')).toEqual(14)`},
    {code: `expect(parseRomanNumerial('XV')).toEqual(15)`},
    {code: `expect(parseRomanNumerial('XVI')).toEqual(16)`},
    {code: `expect(parseRomanNumerial('XVII')).toEqual(17)`},
    {code: `expect(parseRomanNumerial('XVIII')).toEqual(18)`},
    {code: `expect(parseRomanNumerial('XIX')).toEqual(19)`},
    {code: `expect(parseRomanNumerial('XX')).toEqual(20)`},
    {code: `expect(parseRomanNumerial('XXI')).toEqual(21)`},
    {code: `expect(parseRomanNumerial('XXII')).toEqual(22)`},
    {code: `expect(parseRomanNumerial('XXIII')).toEqual(23)`},
    {code: `expect(parseRomanNumerial('XXIV')).toEqual(24)`},
    {code: `expect(parseRomanNumerial('XXX')).toEqual(30)`},
    {code: `expect(parseRomanNumerial('XL')).toEqual(40)`},
    {code: `expect(parseRomanNumerial('L')).toEqual(50)`},
    {code: `expect(parseRomanNumerial('LX')).toEqual(60)`},
    {code: `expect(parseRomanNumerial('LXX')).toEqual(70)`},
    {code: `expect(parseRomanNumerial('LXXX')).toEqual(80)`},
    {code: `expect(parseRomanNumerial('XC')).toEqual(90)`},
    {code: `expect(parseRomanNumerial('C')).toEqual(100)`},
    {code: `expect(parseRomanNumerial('CI')).toEqual(101)`},
    {code: `expect(parseRomanNumerial('CII')).toEqual(102)`},
    {code: `expect(parseRomanNumerial('CC')).toEqual(200)`},
    {code: `expect(parseRomanNumerial('CCC')).toEqual(300)`},
    {code: `expect(parseRomanNumerial('CD')).toEqual(400)`},
    {code: `expect(parseRomanNumerial('D')).toEqual(500)`},
    {code: `expect(parseRomanNumerial('DC')).toEqual(600)`},
    {code: `expect(parseRomanNumerial('DCC')).toEqual(700)`},
    {code: `expect(parseRomanNumerial('DCCC')).toEqual(800)`},
    {code: `expect(parseRomanNumerial('CM')).toEqual(900)`},
    {code: `expect(parseRomanNumerial('M')).toEqual(1000)`},
    {code: `expect(parseRomanNumerial('MI')).toEqual(1001)`},
    {code: `expect(parseRomanNumerial('MII')).toEqual(1002)`},
    {code: `expect(parseRomanNumerial('MIII')).toEqual(1003)`},
    {code: `expect(parseRomanNumerial('MCM')).toEqual(1900)`},
    {code: `expect(parseRomanNumerial('MM')).toEqual(2000)`},
    {code: `expect(parseRomanNumerial('MMI')).toEqual(2001)`},
    {code: `expect(parseRomanNumerial('MMII')).toEqual(2002)`},
    {code: `expect(parseRomanNumerial('MMC')).toEqual(2100)`},  
    {code: `expect(parseRomanNumerial('MMM')).toEqual(3000)`},
    {code: `expect(parseRomanNumerial('MMMDCCXXIV')).toEqual(3724)`}
]