<template>
    <div class="TestResult" :class="{valid, invalid}">
        <div class="code">{{result.code}}</div>
        <div class="result" v-if="invalid">{{result.result}}</div>
        <div class="result" v-if="result.error !== undefined">{{result.error}}</div>
    </div>
</template>

<script>
const {computed} = require("vue")

export default {
    props: {
        result: Object
    },
    setup(props) {
        const valid = computed(function() {
            return props.result.result === true
        })

        const invalid = computed(function() {
            return props.result.result !== true
        })

        return {valid, invalid}
    }
}
</script>

<style lang="scss" scoped>
.TestResult {
    padding: 5px;
    padding-bottom: 5px;
    width: 100%;

    .code {
        font-size: 20px;
    }

    &.valid {
        .code {
            color: #388e3c;
        }
    }

    &.invalid {
        .code {
            color: #d32f2f;
        }
    }
}
</style>