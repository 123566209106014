<template>
    <div/>
</template>

<script>
const ace = require("brace")
require("brace/mode/javascript")
require("brace/theme/monokai")

export default {
    data() {
        return {
            editor: null
        }
    },
    methods: {
        setContent(newContent) {
            this.editor.setValue(newContent, -1)
        }
    },
    mounted() {
        const editor = ace.edit(this.$el)
        editor.getSession().setMode("ace/mode/javascript")
        editor.setTheme("ace/theme/monokai")
        editor.setOptions({
            maxLines: Infinity
        });
        editor.$blockScrolling = Infinity
        editor.setShowPrintMargin(false)

        editor.on("change", () => {
            const content = editor.getValue()
            this.$emit("change", content)
        })

        this.editor = editor
    },
    onUnmounted() {
        this.editor.destroy()
        this.editor.container.remove()
    }
}
</script>