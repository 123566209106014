module.exports.question = "Create a function returns true if the argument is a prime and false if it is not"
module.exports.start = 
`function isPrime(number) {

}`

module.exports.tests =  [
    {code: `expect(isPrime(1)).toEqual(false)`},
    {code: `expect(isPrime(2)).toEqual(true)`},
    {code: `expect(isPrime(4)).toEqual(false)`},
    {code: `expect(isPrime(5)).toEqual(true)`},
    {code: `expect(isPrime(12)).toEqual(false)`},
    {code: `expect(isPrime(2147483647)).toEqual(true)`},
    {code: `expect(isPrime(123213123)).toEqual(false)`}
]